

export default {
    name: "ClockInCalendar",
    props: {
    },
    created() {

        let t = this;

        let d = new Date();

        t.year = d.getFullYear();
        t.month = d.getMonth() + 1;
        t.loadData();
        
    },
    activated() {


    },
    methods: {
        loadData() {
            let t = this;

            if (t.U.clock_in_reviewer == 1) {
                axios.get('employee?role=4').then(res => {
                    if (res.data.status == 1) {
                        let arr = res.data.options.items;
                        arr.forEach(e => {
                            e.fullname = e.lastname + e.firstname;
                            if (e.id == t.U.id) {
                                t.search.employee = e;
                                e.fullname += ' (私)';
                            }
                        });
                        t.list.employee = arr;
                        t.loadMonthlyStats();
                    }
                });
            }

        },

        minusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date(t.year, t.month - 1, 1), -1);
            t.month = r.getMonth() + 1;
            t.year = r.getFullYear();
            t.loadMonthlyStats();
        },

        plusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date(t.year, t.month - 1, 1), 1);
            t.month = r.getMonth() + 1;
            t.year = r.getFullYear();
            t.loadMonthlyStats();
        },


        loadMonthlyStats() {

            let t = this;

            let dayIndexs = ['日', '月', '火', '水', '木', '金', '土'];

            t.list.dateGrid.clear();

            let params = {};

            if (t.search.employee) {
                params.employee_id = t.search.employee.id;
            }

            t.list.dateGrid = [];
            t.list.placeholder = [];

            axios.get('clockIn/loadMonthlyStats/' + t.month, { params: params }).then(res => {
                if (res.data.status == 1) {

                    let dictDate = {};

                    let todayText = (new Date()).format('MM/dd');

                    let day = getDaysInMonth(t.year, t.month);

                    let work_time = res.data.options.work_time;

                    let dictSup = listToDict(res.data.options.sups ?? {}, 'date');

                    t.work_item = work_time;

                    for (let i = 0; i < day; i++) {
                        let curDate = new Date(t.year, t.month - 1, i + 1);
                        let date = curDate.format('MM/dd');
                        let item = {
                            full_date: curDate.format('yyyy/MM/dd'),
                            date: date,
                            day_index_text: dayIndexs[curDate.getDay()],
                            day_index: curDate.getDay(),
                            is_today: date == todayText,
                            clock_ins: [],
                            is_late: false,
                            late_minutes: 0,
                            is_early: false,
                            early_minutes: 0,
                            is_clock_in: false,
                            is_clock_out: false,
                            is_complete: false,
                            working_hour_is_enough: false,
                            daily_working_hour: 0,
                            external_daily_working_hour: 0,
                            sup: null,
                        };
                        item.sup = dictSup[curDate.format('yyyy-MM-dd')];
                        t.list.dateGrid.push(item);
                        dictDate[date] = item;
                    }

                    let days = new Date(t.list.dateGrid[0].full_date).getDay();

                    t.list.placeholder = [];

                    for (let j = 0; j < days; j++) {
                        t.list.placeholder.push('');
                    }

                    let arr = res.data.options.items;

                    arr.forEach(clock_in => {
                        let date = new Date(clock_in.date).format('MM/dd');
                        let item = dictDate[date];
                        item.clock_ins.push(clock_in);
                    });

                    t.list.dateGrid.forEach(item => {

                        if (item.clock_ins.length == 1) {
                            item.is_complete = false;
                        }
                        else if (item.clock_ins.length >= 2) {

                            item.is_complete = true;

                            let start = new Date(item.full_date + ' ' + work_time.start_time);
                            let clock_in = new Date(item.full_date + ' ' + item.clock_ins[0].time);
                            let end = new Date(item.full_date + ' ' + work_time.end_time);
                            let clock_out = new Date(item.full_date + ' ' + item.clock_ins[1].time);

                            item.is_clock_in = true;
                            item.is_clock_out = true;

                            if (work_time.calc_method == 1) {
                                if (start.valueOf() + (10 * 60 * 1000) < clock_in.valueOf()) {
                                    item.is_late = true;
                                    item.late_minutes = Math.floor((clock_in.valueOf() - start.valueOf()) / 60.0 / 1000);
                                }
                                if (end.valueOf() > clock_out.valueOf()) {
                                    item.is_early = true;
                                    item.early_minutes = Math.floor((end.valueOf() - clock_out.valueOf()) / 60.0 / 1000);
                                }

                                let mintues = Math.floor((clock_out.valueOf() - end.valueOf()) / 60.0 / 1000);
                                if (mintues >= 15) {
                                    item.external_daily_working_hour = (mintues / 60).toFixed(1);
                                }
                            }

                            let hour = ((clock_out - clock_in - work_time.reset_minutes * 60.0 * 1000.0) / 1000.0 / 60 / 60).toFixed(1);

                            item.daily_working_hour = hour;

                            item.working_hour_is_enough = hour >= item.daily_hour;

                        }

                    });

                    // t.showDetails(t.list.dateGrid[10]);

                    // t.showDateGridEditor = false;

                    // t.makeSup();
                }
            });

        },

        showDetails(item) {
            let t = this;

            t.item = item;

            t.showDateGridEditor = true;
        },

        makeSup() {

            let t = this;

            t.f = {
                time: '--:--',
                time2: '--:--',
                description: '',
                date: ''
            };

            if (t.item.sup) {
                t.f.time = t.item.sup.time.substr(0, 5);
                t.f.time2 = t.item.sup.time2 ? t.item.sup.time2.substr(0, 5) : '--:--';
                t.f.description = t.item.sup.description;
            }

            t.$forceUpdate();

            t.showSupEditor = true;
        },

        submitSup() {

            let t = this;

            if (t.busying)
                return;

            t.f.date = t.item.full_date;

            if (t.f.time == '--:--') {
                A.err(t.t('補刻時間を選択してください'));
                return;
            }

            if (t.item.clock_ins.length == 0 && t.f.time2 == '--:--') {
                A.err(t.t('補刻時間を選択してください'));
                return;
            }

            if (!t.f.description) {
                A.err(t.t('補刻説明を記入してください（例：忘れました）'));
                return;
            }

            t.busying = true;

            axios.post('clockIn/submitSup', t.f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    t.loadMonthlyStats();

                    t.showSupEditor = false;

                }
                else if (res.data.status == 2) {
                    A.err(t.t('本月の補刻回数がすでに使い果たされました'))
                }
                else if (res.data.status == 3) {
                    A.err(t.t('当日の補刻申請がありますので、重複して提出しないでください'))
                }
            });

        },

        cancelSup() {

            let t = this;

            A.safety('この打刻申請をキャンセルしますか？').then(res => {
                if (res.isConfirmed) {

                    axios.post('clockIn/cancelSup/' + t.item.sup.id).then(res => {
                        if (res.data.status == 1) {
                            t.item.sup = null;
                            t.showSupEditor = false;
                        }
                    });

                }
            });


        },

        selectEmployee(v) {
            this.search.employee = v;
            this.showEmployeeSelector = false;
            this.loadMonthlyStats();
        },
    },
    data() {
        return {
            ready: false,
            busying: false,
            year: 2024,
            month: 11,
            list: {
                dateGrid: [],
                placeholder: [],
                employee: [],
            },
            work_time: null,
            item: null,
            showDateGridEditor: false,
            showSupEditor: false,
            showTimePopover1: false,
            showTimePopover2: false,
            f: {
                time: '--:--',
                time2: '--:--',
                description: '',
                date: ''
            },
            showEmployeeSelector: false,
            search: {
                employee: null,
            },
        };
    },
};

