
import { ImagePreview } from 'vant';
import pickSupTasks from '../driver/pick-sup-tasks';
import doSupTasks from '../driver/do-sup-tasks';

export default {
    components: {
        pickSupTasks,
        doSupTasks
    },
    created() {

        let t = this;

        window._language = 'ja-jp';

        t.loadData();

        t.todayDateStr = (new Date()).format('yyyy. MM. dd');

        let p = getURLParams();

        if (p.v)
            t.view = p.v;

        if (t.U.birthday)
            t.selectedDate = new Date(t.U.birthday);

        if (!t.U.rating)
            t.U.rating = 4;

        if (t.U.token)
            t.refreshProfile();

        t.loadMonthlyStats();

        if (document.getElementById('realtimeStr'))
            document.getElementById('realtimeStr').innerText = (new Date()).format('hh:mm:ss');

        setInterval(function () {
            if (document.getElementById('realtimeStr'))
                document.getElementById('realtimeStr').innerText = (new Date()).format('hh:mm:ss');
        }, 1000);

    },
    activated() {

        let t = this;

        copyFrom(t.f, t.U);
    },
    methods: {

        logout() {
            localStorage.clear();
            location.reload('/#/signIn');
        },

        refreshProfile() {

            let t = this;
            axios.post('employee/refreshProfile').then(res => {
                if (res.data.status == 1) {
                    if (!res.data.options.item) {
                        localStorage.removeItem('_m');
                        location.replace('/#/signin');
                    }
                    else {
                        localStorage['_m'] = JSON.stringify(res.data.options.item);
                        mirror(res.data.options.item, t.U);
                        copyFrom(t.f, t.U);
                    }
                }
            });

        },

        loadData() {
            let t = this;

            axios.get('cleanArea?_with=items').then(res => {
                if (res.data.status == 1) {
                    t.list.cleanArea = res.data.options.items;

                    let date = (new Date()).format('yyyy-MM-dd');

                    axios.get('cleanTask?_with=room,order,results,roomCleanAreas&date=' + date).then(res => {
                        if (res.data.status == 1) {
                            let arr = res.data.options.items;

                            arr.forEach(item => {
                                item.emergency = false;
                                if (item.status == 1 && (new Date()).getHours() >= 13)
                                    item.emergency = true;

                            });

                            t.list.cleanTask = arr;

                            t.list.startedCleanTask = t.list.cleanTask.filter(item => item.status != 1);
                        }
                    });
                }
            });


        },

        refreshTaskTabs() {

            let t = this;

            t.list.startedCleanTask = t.list.cleanTask.filter(item => item.status != 1);
        },

        setView(v) {

            let t = this;

            if (v == t.view)
                return;

            if (v == 'report') {
                let tasks = t.list.startedCleanTask;
                if (tasks.length < 1)
                    A.toast('No cleaning tasks started', 1000, 'info');
                else {
                    t.editTaskItem(tasks[0]);
                }

                return;
            }

            t.view = v;

            t.loadMonthlyStats();

        },

        setStatus(item, status) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.put('cleanTask/' + item.id, { 'status': status }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    item.status = status;

                    if (status == 2) {
                        t.refreshTaskTabs();
                        t.view = 'report';
                        t.editTaskItem(item);
                    }
                }
            });

        },

        setFinish(item) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.put('cleanTask/' + item.id, { 'is_finish': 1 }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    t.list.cleanTask.remove(item);
                    t.refreshTaskTabs();

                }
            });

        },

        editTaskItem(item) {
            let t = this;
            t.currentTask = item;

            let arr = [];

            let selectedIds = [];
            let dictAreaImgs = {};

            if (t.currentTask.results && t.currentTask.results.length > 0) {
                let dictArea = {};
                t.currentTask.results.forEach(res => {
                    dictArea[res.area_id] = res;
                    // if (res.selection) {
                    //     selectedIds.append(res.selection.split(','));
                    // }
                    // if (res.img_urls) {
                    //     dictAreaImgs[res.area_id] = res.img_urls.split(',');
                    // }
                });

                localStorage['task_tmp_' + t.currentTask.id] = JSON.stringify(dictArea);
            }

            let taskResults = localStorage['task_tmp_' + t.currentTask.id];

            if (taskResults) {

                taskResults = JSON.parse(taskResults);

                t.dict.editingTask[t.currentTask.id] = taskResults;

                let results = [];

                for (const k in taskResults) {
                    results.push(taskResults[k]);
                }

                results.forEach(res => {
                    if (res.selection) {
                        selectedIds.append(res.selection.split(','));
                    }
                    if (res.img_urls) {
                        dictAreaImgs[res.area_id] = res.img_urls.split(',');
                    }
                });
            }

            let dictRCA = {};

            t.currentTask.room_clean_areas.forEach(rca => {

                dictRCA[rca.area_id] = rca;

            });

            t.list.cleanArea.forEach(area => {

                area.imgs = [];
                area.columns = [];

                area.items.forEach(item => {
                    item.selected = false;

                    if (selectedIds.indexOf(item.id.toString()) > -1)
                        item.selected = true;
                });

                if (dictAreaImgs[area.id]) {
                    area.imgs = dictAreaImgs[area.id];
                }

                if (area.column == 1) {
                    area.columns.push(area.items);
                }
                else if (area.column == 2) {
                    let rows = area.rows.split(',');
                    area.columns.push(area.items.slice(0, Number(rows[0])));
                    area.columns.push(area.items.slice(Number(rows[0]), Number(rows[1]) + Number(rows[0])));
                }

                let rca = dictRCA[area.id];

                if (rca && rca.quantity > 0) {
                    area.quantity = rca.quantity;
                    arr.push(area);
                }

            });

            t.list.roomArea = arr;

            t.view = 'report';
        },

        updateArea(area) {

            let t = this;

            t.$forceUpdate();
            let selectedIds = [];

            area.items.forEach(item => {
                if (item.selected)
                    selectedIds.push(item.id);
            });

            let f = {
                task_id: t.currentTask.id,
                area_id: area.id,
                selection: selectedIds.join(',')
            };

            if (!t.dict.editingTask[t.currentTask.id])
                t.dict.editingTask[t.currentTask.id] = {};

            if (t.dict.editingTask[t.currentTask.id][area.id])
                t.dict.editingTask[t.currentTask.id][area.id].selection = f.selection;
            else
                t.dict.editingTask[t.currentTask.id][area.id] = f;

            localStorage['task_tmp_' + t.currentTask.id] = JSON.stringify(t.dict.editingTask[t.currentTask.id]);

            // axios.post('cleanTask/saveResult', f).then(res => {
            //     if (res.data.status == 1) {

            //     }
            // });


        },

        uploadPicture(area) {

            let t = this;

            t.list.blobs.clear();

            ImageUploader.onSelectImage = null;
            ImageUploader.onSelectImages = (files) => {

                if (files.size > 6) {
                    A.err('No more than 6 images can be selected at a time');
                    return;
                }

                t.list.uploadQueue.clear();

                for (let i = 0; i < files.length; i++) {
                    t.list.uploadQueue.push(files[i]);
                }

                if (t.list.uploadQueue.length > 0) {
                    t.loading = true;
                    t.startScale(area);
                }

            };

            ImageUploader.openSelector(true);
        },

        takePicture(area) {

            let t = this;

            t.list.blobs.clear();

            ImageUploader.onSelectImages = null;
            ImageUploader.onSelectImage = (file) => {

                if (file) {
                    t.list.uploadQueue.clear();

                    t.list.uploadQueue.push(file);

                    if (t.list.uploadQueue.length > 0) {
                        t.loading = true;
                        t.startScale(area);
                    }

                }
            };

            ImageUploader.openCamera();

        },

        startScale(area) {
            let t = this;

            if (t.list.uploadQueue.length > 0) {

                const file = t.list.uploadQueue[0];

                const reader = new FileReader();

                reader.onload = function (e) {

                    const img = new Image();

                    img.src = e.target.result;

                    img.onload = function () {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        // 设置最大宽度和高度
                        const maxWidth = 1000;
                        const maxHeight = 1000;

                        let width = img.width;
                        let height = img.height;

                        // 计算缩放比例
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }

                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }

                        // 设置 canvas 大小
                        canvas.width = width;
                        canvas.height = height;

                        // 在 canvas 上绘制缩放后的图像
                        ctx.drawImage(img, 0, 0, width, height);

                        // 生成 Blob
                        canvas.toBlob(blob => {

                            t.list.blobs.push(blob);

                            t.list.uploadQueue.shift();

                            if (t.list.uploadQueue.length > 0) {
                                t.startScale(area);
                            }
                            else {
                                t.startUpload(area);
                            }

                        }, 'image/jpeg', 1);
                    };

                };

                reader.readAsDataURL(file);

            }
        },

        startUpload(area) {

            let t = this;
            let fd = new FormData();
            let idx = 0;

            t.list.blobs.forEach(blob => {
                fd.append('image_' + idx, blob);
                idx++;
            });

            fd.append('qty', idx);
            fd.append('task_id', t.currentTask.id);

            axios.post('cleanTask/saveMultipleImage', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {

                if (res.data.status == 1) {

                    area.imgs.append(res.data.options.img_urls);

                    t.$forceUpdate();

                    let selectedIds = [];

                    area.items.forEach(item => {
                        if (item.selected)
                            selectedIds.push(item.id);
                    });

                    let f = {
                        task_id: t.currentTask.id,
                        area_id: area.id,
                        img_urls: area.imgs.join(','),
                        selection: selectedIds.join(',')
                    };

                    if (!t.dict.editingTask[t.currentTask.id])
                        t.dict.editingTask[t.currentTask.id] = {};

                    t.dict.editingTask[t.currentTask.id][area.id] = f;

                    localStorage['task_tmp_' + t.currentTask.id] = JSON.stringify(t.dict.editingTask[t.currentTask.id]);

                    t.loading = false;

                    // t.updateArea(area);
                    // axios.post('cleanTask/saveResult', f).then(res => {
                    //     if (res.data.status == 1) {
                    //         t.loading = false;
                    //     }
                    // });

                }
            });

        },

        removeImg(area, img) {
            let t = this;
            A.safety('Are you sure to delete this photo?').then(res => {
                if (res.isConfirmed) {
                    area.imgs.remove(img);
                    t.$forceUpdate();

                    let selectedIds = [];

                    area.items.forEach(item => {
                        if (item.selected)
                            selectedIds.push(item.id);
                    });

                    let f = {
                        task_id: t.currentTask.id,
                        area_id: area.id,
                        img_urls: area.imgs.join(','),
                        selection: selectedIds.join(',')
                    };

                    if (!t.dict.editingTask[t.currentTask.id])
                        t.dict.editingTask[t.currentTask.id] = {};

                    t.dict.editingTask[t.currentTask.id][area.id] = f;

                    localStorage['task_tmp_' + t.currentTask.id] = JSON.stringify(t.dict.editingTask[t.currentTask.id]);


                    // axios.post('cleanTask/saveResult', f).then(res => {
                    //     if (res.data.status == 1) {
                    //         t.loading = false;
                    //     }
                    // });
                }
            });

        },

        preview(area, idx) {

            let t = this;
            let imgUrls = [];

            if (area.imgs.length > 0) {
                area.imgs.forEach(img => {
                    imgUrls.push(t.url(img));
                });

                ImagePreview({
                    images: imgUrls,
                    startPosition: idx,
                    closeable: true,
                });
            }
        },

        submit() {
            let t = this;

            if (t.busying)
                return;

            let taskResults = localStorage['task_tmp_' + t.currentTask.id];

            if (!taskResults) {
                A.err('空のコンテンツを送信しないでください');
                return;
            }

            taskResults = JSON.parse(taskResults);

            let results = [];

            for (const k in taskResults) {
                results.push(taskResults[k]);
            }

            A.safety('再確認をお願いします。').then(res => {
                if (res.isConfirmed) {

                    t.busying = true;

                    axios.post('cleanTask/saveResults', { 'results': results }).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            localStorage.removeItem('task_tmp_' + t.currentTask.id);
                            t.setStatus(t.currentTask, 3);
                            t.view = 'tasks';
                        }
                    });


                }
            });

        },

        tapAreaItem(item, area) {
            let t = this;
            if (t.currentTask.status == 3) { A.err('提出済み、編集不可'); return; }
            item.selected = !item.selected;
            t.updateArea(area);
        },

        onSelectDate() {
            this.f.birthday = this.selectedDate.format('yyyy-MM-dd');
            this.showCalendar = false;
            this.$forceUpdate();
        },

        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 600,
                minWdith: 600,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#fff',
            }).toBlob((blob) => {

                if (t.uploadImgType == 'rc') {
                    t.rcImageBlob = blob;
                    t.rcIsUploaded = true;
                    t.f.rc_img_url = getObjectURL(blob);
                }

                else if (t.uploadImgType == 'avatar') {
                    t.avatarImageBlob = blob;
                    t.avatarIsUploaded = true;
                    t.f.avatar_url = getObjectURL(blob);
                }

                t.closeCropper();
            });
        },

        uploadRC() {

            let t = this;

            t.uploadImgType = 'rc';

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 4 / 3,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        uploadAvatar() {

            let t = this;

            t.uploadImgType = 'avatar';

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 1 / 1,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        saveProfile() {
            let t = this;
            let f = t.f;

            if (t.busying)
                return;

            let go = true;

            if (!f.tel) {
                A.err('Telephone number is Required.');
                go = false;
            }

            if (!f.birthday) {
                A.err('Birthday is Required.');
                go = false;
            }

            if (!f.email) {
                A.err('Email is Required.');
                go = false;
            }

            if (!/^[a-zA-Z0-9\.]{1,}@[a-zA-Z0-9\.]{1,}[a-zA-Z0-9]$/.test(f.email)) {
                A.err('Please enter a valid email address.');
                go = false;
            }

            if (!f.emergency_tel) {
                A.err('Emergency telephone number is Required.');
                go = false;
            }

            if (!f.address) {
                A.err('Address is Required.');
                go = false;
            }

            if (!go)
                return;

            t.busying = true;

            let data = getFields(f, ['address', 'email', 'tel', 'emergency_tel', 'rc_img_url', 'avatar_url', 'gender', 'birthday', 'desc']);

            let fd = new FormData();

            for (const key in data) {
                fd.append(key, data[key]);
            }

            if (t.rcIsUploaded)
                fd.append('rc_image', t.rcImageBlob);

            if (t.avatarIsUploaded)
                fd.append('avatar_image', t.avatarImageBlob);

            t.busying = true;

            axios.post('employee/saveProfile', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {

                t.busying = false;

                t.avatarImageBlob = null;
                t.avatarIsUploaded = false;
                t.rcImageBlob = null;
                t.rcIsUploaded = false;

                if (res.data.status == 1) {
                    localStorage['_m'] = JSON.stringify(res.data.options.item);
                    t.$router.replace('/cleaner?v=profile');
                    location.reload();
                }
            });
        },

        loadMonthlyStats() {

            let t = this;

            let dayIndexs = ['日', '月', '火', '水', '木', '金', '土'];
            if (t.view == 'attendance') {

                t.list.dateGrid.clear();

                axios.post('cleanTask/readMonthly/' + t.month).then(res => {
                    if (res.data.status == 1) {

                        let dictDate = {};

                        let todayText = (new Date()).format('MM/dd');
                        let todayVal = new Date();

                        let startDate = new Date(res.data.options.start_date);
                        let endDate = new Date(res.data.options.end_date);

                        let i = 0;

                        while (true) {
                            let curDate = new Date(res.data.options.start_date);
                            curDate.setDate(curDate.getDate() + i);
                            if (curDate > endDate)
                                break;
                            let date = curDate.format('MM/dd');
                            let dayIndex = dayIndexs[curDate.getDay()];
                            let item = {
                                date: date,
                                day_index: dayIndex,
                                week_index: curDate.getDay(),
                                tasks: [],
                                is_today: date == todayText,
                                is_past: curDate < todayVal
                            };
                            dictDate[date] = item;
                            t.list.dateGrid.push(item);
                            i++;
                        }

                        let arr = res.data.options.items;

                        arr.forEach(task => {
                            let date = new Date(task.date).format('MM/dd');
                            let item = dictDate[date];
                            item.tasks.push(task);
                        });
                    }
                });

            }

        },

        minusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date((new Date()).getFullYear(), t.month - 1, 1), -1);
            t.month = r.getMonth() + 1;
            t.loadMonthlyStats();
        },

        plusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date((new Date()).getFullYear(), t.month - 1, 1), 1);
            t.month = r.getMonth() + 1;
            t.loadMonthlyStats();
        }

    },
    data() {
        return {
            month: (new Date()).getMonth() + 1,
            selectedDate: null,
            minDate: new Date('1950/01/01'),
            maxDate: new Date('2023/01/01'),
            showCalendar: false,
            loading: false,
            currentTask: null,
            view: 'profile',
            todayDateStr: '',
            realtimeStr: '',
            dict: {
                editingTask: {}
            },
            list: {
                cleanArea: [],
                cleanTask: [],
                startedCleanTask: [],
                roomArea: [],
                uploadQueue: [],
                blobs: [],
                dateGrid: []
            },
            f: {
                gender: 1,
                address: '',
                email: '',
                tel: '',
                birthday: '',
                emergency_tel: '',
                desc: '',
                rc_img_url: '',
                avatar_url: '',
                id: '',
            },
            stats: {
                cleaned: 0
            },
            rcIsUploaded: false,
            rcImageBlob: null,
            avatarIsUploaded: false,
            avatarImageBlob: null,
            cropper: null,
            uploadImgType: '',

            //attendace
            attendanceExpanded: true,

        };
    },
    computed: {
    }
};
