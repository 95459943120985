

import SortPicker from '../components/sort-picker.vue';

export default {
    name: 'sup_tasks',

    components: {
        'sort-picker': SortPicker
    },

    created() {

        let t = this;

        document.body.classList.add('pc');

        axios.get("employee?role=2,3").then((res) => {
            if (res.data.status == 1) {

                let items = res.data.options.items;

                t.employeeDict = listToDict(items);

                let selection = [];

                if (localStorage["selected_employee_ids"]) {
                    selection = localStorage["selected_employee_ids"].split(",");
                    selection.forEach(id => {
                        if (t.employeeDict[id])
                            t.list.selectedEmployee.push(t.employeeDict[id]);
                    });
                }

                t.list.employee = items;

                axios.get('room').then(res => {
                    if (res.data.status == 1) {
                        let rooms = res.data.options.items;

                        t.initRooms(rooms);

                        t.roomDict = listToDict(rooms);

                        t.list.room = rooms;

                        axios.get('group').then(res => {
                            if (res.data.status == 1) {
                                let arr = res.data.options.items;
                                arr.forEach(group => {
                                    group.rooms = [];
                                    let roomIds = group.room_ids.split(',');
                                    roomIds.forEach(roomId => {
                                        group.rooms.push(t.roomDict[roomId]);
                                    });
                                });

                                t.list.group = arr;
                            }
                        });

                        t.onSelectStartDate(t.getMondayOfThisWeek());
                    }
                });


            }
        });

        // let maxDate = t.getMondayOfThisWeek();

        // maxDate.setDate(maxDate.getDate() + 7 * t.weeks);

        // t.maxDate = maxDate;

        t.initSocket();

    },

    beforeDestroy() {
        if (window.sok) {
            try {
                window.sok.close(1000, "Closing connection");
            } catch (ex) { }
        }
    },

    activated() {

        let t = this;

    },

    methods: {

        printA3() {
            if (window._printStyleTag) window._printStyleTag.remove();
            let style =
                "@media print { .btn-print2{ display: none !important; } .main-content {  padding-left: 0 !important;  width: calc(100vw + 10px) !important; } .dashboard-page {  padding: 0; } .dashboard-page .header {  display: none; } .rooms-block {  margin-top: 0; } .room-rows {  height: calc(100vh - 22px) !important;  width: 100vw !important;  overflow: visible !important;  transform: scale(1);  transform-origin: left top; } .page-left{  display: none !important; } .rooms-block{  margin-top:0 !important;  border: none !important; } @page {  size: A3 landscape; }}";

            let s = document.createElement("style");
            s.innerHTML = style;
            document.body.appendChild(s);
            window._printStyleTag = s;
            window.print();
        },

        printA4() {
            if (window._printStyleTag) window._printStyleTag.remove();
            let style =
                "@media print { .btn-print2{ display: none !important; } .main-content {  padding-left: 0 !important; } .dashboard-page {  padding: 0; } .dashboard-page .header {  display: none; } .rooms-block {  margin-top: 0; } .room-rows {  height: calc(100vh - 22px) !important;  overflow: visible !important;  transform: scale(0.69);  transform-origin: left top; } .page-left{  display: none !important; } .rooms-block{  margin-top:0 !important;  border: none !important; } @page {  size: A4 landscape; }}";

            let s = document.createElement("style");
            s.innerHTML = style;
            document.body.appendChild(s);
            window._printStyleTag = s;
            window.print();
        },

        onSelectStartDate(d) {
            let t = this;

            t.currentDate = d;

            t.startDate = d;

            t.weeks = 16;

            t.endDate = new Date(
                t.startDate.getFullYear(),
                t.startDate.getMonth(),
                t.startDate.getDate() + t.weeks * 7
            );

            let arr = [];

            for (let i = 0; i < t.weeks; i++) {

                let sd = new Date(d.valueOf());
                sd.setDate(sd.getDate() + i * 7);

                let ed = new Date(sd.valueOf());
                ed.setDate(ed.getDate() + 6);

                arr.push({
                    start_date: sd.format('yyyy-MM-dd'),
                    end_date: ed.format('yyyy-MM-dd'),
                    passed: t.today.valueOf() > ed.valueOf(),
                    count_left: 0
                });

            }

            t.list.week = arr;

            let p = {
                start_date: t.list.week[0].start_date,
                end_date: t.list.week[t.list.week.length - 1].end_date,
            };

            axios.get('supTask/readStatsByDate', { params: p }).then(res => {
                if (res.data.status == 1) {

                    let dict = res.data.options.dict;

                    t.list.week.forEach(week => {
                        let count_left = 0;
                        for (let i = 0; i < 7; i++) {
                            let d = new Date(week.start_date);
                            d.setDate(d.getDate() + i);
                            let dtext = d.format('yyyy-MM-dd');
                            if (dict[dtext]) {
                                count_left += Number(dict[dtext]);
                            }
                        }
                        week.count_left = count_left;
                    });

                    t.selectWeek(t.list.week[0]);

                }
            });

            t.showDatePopover2 = false;
        },

        initRooms(rooms) {
            rooms.forEach(room => {
                room.selected = false;
                room.disabled = false;
                room.status = 0;
                room.alert = 0;
                room.sup_task_id = null;
                room.employee_id = null;
                room.frozen = false;
            });
        },

        selectWeek(week) {

            let t = this;

            t.initRooms(t.list.room);

            t.curWeek = week;

            let todayText = (new Date()).format('yyyy-MM-dd');

            let p = {
                date: [week.start_date, week.end_date].join(',')
            };

            axios.get('supTask', { params: p }).then(res => {
                if (res.data.status == 1) {

                    let items = res.data.options.items;

                    let dict = {};

                    items.forEach(item => {
                        t.roomDict[item.room_id].disabled = true;
                        t.roomDict[item.room_id].employee_id = item.employee_id;
                        t.roomDict[item.room_id].status = item.status;
                        t.roomDict[item.room_id].alert = item.alert;
                        t.roomDict[item.room_id].sup_task_id = item.sup_task_id;

                        if (!dict[item.date])
                            dict[item.date] = {};
                        if (!dict[item.date][item.employee_id])
                            dict[item.date][item.employee_id] = [];
                        dict[item.date][item.employee_id].push(item);
                    });


                    t.list.employeeInPanel.clear();

                    t.list.employeeInPanel.append(t.list.selectedEmployee);

                    t.employeeInPanelDict = listToDict(t.list.employeeInPanel);

                    t.list.employeeInPanel.forEach(e => {
                        e.days = [];
                        e.count_total = 0;
                        for (let i = 0; i < 7; i++) {
                            let d = new Date(week.start_date);
                            d.setDate(d.getDate() + i);
                            let dtext = d.format('yyyy-MM-dd');

                            let day = {
                                date: dtext,
                                count_total: 0,
                                count_unfinish: 0,
                                rooms: [],
                                isToday: dtext == todayText,
                                day_suffix: ["日", "月", "火", "水", "木", "金", "土"][d.getDay()]
                            }
                            if (dict[dtext] && dict[dtext][e.id]) {
                                day.count_total = dict[dtext][e.id].length;
                                dict[dtext][e.id].forEach(supTask => {
                                    day.rooms.push(t.roomDict[supTask.room_id]);
                                    t.roomDict[supTask.room_id]._day = day;
                                    t.roomDict[supTask.room_id].sup_task_id = supTask.id;
                                    if (supTask.status != 3)
                                        day.count_unfinish++;
                                });
                            }

                            e.count_total += day.count_total;
                            e.days.push(day);
                        }
                    });

                    t.$forceUpdate();
                }
            });
        },

        refresh() {
            let t = this;
            if (t.list.week.length > 0)
                t.selectWeek(t.curWeek || t.list.week[0]);
        },

        scrollToDate(date) {
            this.$refs.calendar.scrollToDate(date);
        },

        formatter(day) {

            if (day.date.getDay() == 1) {
                day.bottomInfo = '月曜日';
            }
            else {
                day.type = 'disabled';
            }
            return day;
        },

        refreshSelection() {
            let t = this;
            let res = t.$refs.sortPicker.selection;

            if (res.length > 0) {
                let arr = [];
                res.forEach(item => {
                    arr.push(item.id);
                });
                localStorage["selected_employee_ids"] = arr.join(",");
            }

            t.list.selectedEmployee.clear();
            t.list.selectedEmployee.append(res);
            t.$forceUpdate();

        },

        editSupTasks(day, employee) {
            let t = this;

            t.list.room.forEach(room => {
                room.selected = false;
            });

            t.curEmployee = employee;

            let p = {
                start_date: day.date,
                end_date: day.date,
            };

            axios.get('cleanTask/readCleanDates', { params: p }).then(res => {
                if (res.data.status == 1) {

                    let cleanDateDict = res.data.options.dict;

                    t.list.room.forEach(room => {
                        room.selected = false;
                    });

                    t.curDay = day;

                    let dict = listToDict(day.rooms);

                    t.list.group.forEach(group => {
                        group.rooms.forEach(room => {
                            if (dict[room.id]) {
                                room.selected = true;
                            }
                            room.frozen = false;
                            if (cleanDateDict[day.date]) {
                                if (cleanDateDict[day.date].indexOf(room.id) < 0)
                                    room.frozen = true;
                            }
                        });
                    });

                }
            });

            t.showSupTaskEditor = true;
        },

        selectRoom(room) {

            let t = this;

            if (t.busying)
                return;

            if (room.frozen)
                return;

            t.curRoom = room;

            if (room.disabled) {
                if (room.employee_id && (room.employee_id == t.U.id || t.U.role == 1))
                    t.showCancelAlert = true;
                return;
            }

            if (t.curDay.rooms.indexOf(room) > -1) {

                t.busying = true;

                axios.delete('supTask/' + room.sup_task_id).then(res => {
                    if (res.data.status == 1) {

                        room.disabled = false;
                        room.selected = false;
                        room.sup_task_id = null;
                        room.employee_id = null;
                        room.status = 0;
                        room.alert = 0;

                        let day = t.curDay;

                        room._day = null;

                        day.rooms.remove(room);
                        day.count_total = day.rooms.length;

                        t.curRoom = null;

                        t.sendMessage({
                            type: 'unlock',
                            date: t.curWeek.start_date,
                            task_date: t.curDay.date,
                            room_ids: [room.id],
                        });

                        t.$forceUpdate();
                    }
                    t.busying = false;
                });

            }
            else {

                if (t.curDay.rooms.length >= 16) {
                    return;
                }

                let f = {
                    room_id: room.id,
                    employee_id: t.curEmployee.id,
                    date: t.curDay.date,
                    status: 1,
                };

                t.busying = true;

                axios.post('supTask', f).then(res => {
                    if (res.data.status == 1) {
                        t.curDay.rooms.push(room);
                        t.curDay.count_total = t.curDay.rooms.length;
                        room.selected = true;
                        room.status = res.data.options.status;
                        room.sup_task_id = res.data.options.id;
                        room.employee_id = res.data.options.employee_id;
                        room._day = t.curDay;
                        if (t.curEmployee && room.employee_id == t.curEmployee.id)
                            room.disabled = true;
                        t.sendMessage({
                            type: 'lock',
                            date: t.curWeek.start_date,
                            task_date: t.curDay.date,
                            time: (new Date()).format('yyyy-MM-dd hh:mm:ss'),
                            room_id: room.id,
                            employee_id: t.curEmployee.id,
                            sup_task_id: room.sup_task_id,
                            status: room.status,
                        });
                        t.$forceUpdate();
                    }
                    else if (res.data.status == 2) {
                        A.err('この部屋は今週割り当てられた。');
                    }
                    t.busying = false;
                });

            }

            t.$forceUpdate();
        },

        removeSupTask() {

            let t = this;

            let room = t.curRoom;

            axios.delete('supTask/' + room.sup_task_id).then(res => {
                if (res.data.status == 1) {
                    t.sendMessage({
                        type: 'unlock',
                        date: t.curDay.date,
                        room_ids: [room.id],
                    });

                    room.disabled = false;
                    room.selected = false;
                    room.sup_task_id = null;
                    room.employee_id = null;
                    room.status = 0;
                    room.alert = 0;

                    let day = room._day;

                    room._day = null;

                    day.rooms.remove(room);
                    day.count_total = day.rooms.length;

                    t.curRoom = null;
                }
            });

        },

        save() {

            let t = this;

            // let roomIds = [];

            // t.curDay.rooms.forEach(room => {

            //     if (!room.sup_task_id) {
            //         roomIds.push(room.id);
            //     }

            // });

            // if (roomIds.length > 0) {
            //     axios.post('supTask/batchSave', { date: t.curDay.date, room_ids: roomIds.join(','), employee_id: t.curEmployee.id }).then(res => {
            //         if (res.data.status == 1) {
            //             t.selectWeek(t.curWeek);
            //             A.toast('Saved.');
            //         }
            //     });
            // }

            t.showSupTaskEditor = false;
        },

        receiveMessage(message) {
            let t = this;
            let data = JSON.parse(message.data);
            if (data.event == 'room_sync') {
                let date = data.extras.date;
                if (date == t.curWeek.start_date) {
                    if (data.extras.type == 'lock') {
                        t.list.room.forEach(room => {
                            if (room.id == data.extras.room_id) {
                                room.disabled = true;
                                room.employee_id = data.extras.employee_id;
                                room.status = data.extras.status;
                                room.sup_task_id = data.extras.sup_task_id;

                                if (t.employeeInPanelDict[data.extras.employee_id] && t.employeeInPanelDict[data.extras.employee_id].days) {

                                    t.employeeInPanelDict[data.extras.employee_id].days.forEach(day => {

                                        if (day.date == data.extras.task_date) {
                                            room._day = day;
                                            day.rooms.push(room);
                                            day.count_total = day.rooms.length;
                                        }

                                    });
                                }

                            }
                        });

                    }
                    else if (data.extras.type == 'unlock') {
                        t.list.room.forEach(room => {
                            if (data.extras.room_ids.indexOf(room.id) > -1) {
                                room.disabled = false;
                                room.selected = false;
                                room.employee_id = null;
                                room.status = 0;
                                if (room._day) {
                                    room._day.rooms.remove(room);
                                }
                                t.$forceUpdate();
                            }
                        });
                    }
                }
            }
        },

        supTaskCount(e) {
            let count = 0;
            e.days.forEach(day => {
                count += day.rooms.length;
                day.count_total = day.rooms.length;
                let count_unfinish = 0;
                day.rooms.forEach(room => {
                    if (room.status != 3)
                        count_unfinish++;
                });
                day.count_unfinish = count_unfinish;
            })
            e.count_total = count;
            return e.count_total;
        },

        clickRoom(room) {
            let t = this;
            if (room.alert == 1) {
                t.curRoom = room;
                t.showChangedAlert = true;
            }
        },

    },

    data() {
        return {
            busying: false,
            showDisabledAlert: false,
            showCancelAlert: false,
            showCancelAlert4Driver: false,
            showChangedAlert: false,
            curWeek: null,
            curRoom: null,
            minDate: new Date("2024/01/01"),
            currentDate: null,
            startDate: null,
            endDate: null,
            today: Date.today(),
            employeeDict: {},
            employeeInPanelDict: {},
            roomDict: {},
            weeks: 16,
            showDatePopover2: false,
            showEmployeeEditor: false,
            showSupTaskEditor: false,
            list: {
                employee: [],
                employeeInPanel: [],
                selectedEmployee: [],
                week: [],
                room: [],
                group: [],
            },
            curDay: {},
            curEmployee: {}
        };
    },

    computed: {
    }
};
