
export default {
    name: "UserProfile",
    props: {
        employee: {
            type: Object,
            required: true
        },
        afterDelete: {
            type: Function,
            required: true
        },
    },
    created() {

        let t = this;

        mirror2(t.employee, t.f);

        console.log(t.employee, t.f);


    },
    methods: {

        closeCropper() {
            $('.cropper-item').hide();
        },

        onSelectDate() {
            this.f.birthday = this.selectedDate.format('yyyy-MM-dd');
            this.showCalendar = false;
            this.$forceUpdate();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 600,
                minWdith: 600,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#fff',
            }).toBlob((blob) => {

                if (t.uploadImgType == 'rc') {
                    t.rcImageBlob = blob;
                    t.rcIsUploaded = true;
                    t.f.rc_img_url = getObjectURL(blob);
                }

                else if (t.uploadImgType == 'avatar') {
                    t.avatarImageBlob = blob;
                    t.avatarIsUploaded = true;
                    t.f.avatar_url = getObjectURL(blob);
                }

                t.closeCropper();
            });
        },


        uploadRC() {

            let t = this;

            t.uploadImgType = 'rc';

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 4 / 3,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        uploadAvatar() {

            let t = this;

            t.uploadImgType = 'avatar';

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 1 / 1,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        saveProfile() {
            let t = this;
            let f = t.f;

            if (t.busying)
                return;

            let go = true;

            if (!f.tel) {
                A.err('Telephone number is Required.');
                go = false;
            }

            if (!f.birthday) {
                A.err('Birthday is Required.');
                go = false;
            }

            if (!f.email) {
                A.err('Email is Required.');
                go = false;
            }

            if (!/^[a-zA-Z0-9\.]{1,}@[a-zA-Z0-9\.]{1,}[a-zA-Z0-9]$/.test(f.email)) {
                A.err('Please enter a valid email address.');
                go = false;
            }

            if (!f.emergency_tel) {
                A.err('Emergency telephone number is Required.');
                go = false;
            }

            if (!f.address) {
                A.err('Address is Required.');
                go = false;
            }

            if (!go)
                return;

            t.busying = true;

            let data = getFields(f, ['address', 'email', 'tel', 'emergency_tel', 'rc_img_url', 'avatar_url', 'gender', 'birthday', 'desc']);

            let fd = new FormData();

            for (const key in data) {
                fd.append(key, data[key]);
            }

            if (t.rcIsUploaded)
                fd.append('rc_image', t.rcImageBlob);

            if (t.avatarIsUploaded)
                fd.append('avatar_image', t.avatarImageBlob);

            t.busying = true;

            axios.post('employee/saveProfile', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {

                t.busying = false;

                t.avatarImageBlob = null;
                t.avatarIsUploaded = false;
                t.rcImageBlob = null;
                t.rcIsUploaded = false;

                if (res.data.status == 1) {
                    mirror(t.f, t.employee);
                }
            });
        },

        deleteAccount() {

            let t = this;

            if (t.busying)
                return;

            A.safety('Are you sure delete this account?').then(res => {
                if (res.isConfirmed) {

                    t.busying = true;

                    axios.post('employee/deleteAccount/' + t.employee.id).then(res => {

                        t.busying = false;

                        if (res.data.status == 1) {
                            t.afterDelete();
                        }
                    });
                }
            })

        }

    },
    data() {
        return {
            month: (new Date()).getMonth() + 1,
            selectedDate: null,
            minDate: new Date('1950/01/01'),
            maxDate: new Date('2023/01/01'),
            showCalendar: false,
            view: 'profile',
            list: {
            },
            f: {
                gender: 1,
                address: '',
                email: '',
                tel: '',
                birthday: '',
                emergency_tel: '',
                desc: '',
                rc_img_url: '',
                avatar_url: '',
                id: '',
            },
            stats: {
                cleaned: 0
            },
            rcIsUploaded: false,
            rcImageBlob: null,
            avatarIsUploaded: false,
            avatarImageBlob: null,
            cropper: null,
            uploadImgType: '',
        };
    },
};
