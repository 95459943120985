
import SortPicker from '../components/sort-picker.vue';
import AddRoom from './add-room.vue';
import ModifyLockPwdVue from './modify-lock-pwd.vue';
export default {
    name: 'room',
    components: {
        'sort-picker': SortPicker,
        'add-room': AddRoom,
        'modify-lock-pwd': ModifyLockPwdVue
    },
    created() {

        let t = this;
        document.body.classList.add('pc');

        axios.get('room?_with=cleanAreas').then(res => {
            if (res.data.status == 1) {
                t.list.room = res.data.options.items;
            }
        });

    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {

            let t = this;

            t.inited = false;

        },

        editRoom(room) {
            this.room = room;
            this.showAddRoom = true;
        }


    },
    data() {
        return {
            showAddRoom: false,
            showModifyPwd: false,
            room:null,
            list: {
                room: []
            }
        };
    },
    computed: {
    }
};
