
import { ImagePreview } from 'vant';
import WorklyReport from './workly-report.vue';
import ClockIn from './clock-in.vue';
import ClockInCalendar from './clock-in-calendar.vue';
import ClockInSupReview from './clock-in-sup-review.vue';

export default {
    components: {
        WorklyReport,
        ClockIn,
        ClockInCalendar,
        ClockInSupReview
    },
    created() {

        let t = this;

        window._language = 'ja-jp';

        t.loadData();

        t.todayDateStr = (new Date()).format('yyyy. MM. dd');

        let p = getURLParams();

        if (p.v)
            t.view = p.v;

        if (t.U.birthday)
            t.selectedDate = new Date(t.U.birthday);

        if (!t.U.rating)
            t.U.rating = 4;

        if (t.U.token)
            t.refreshProfile();

        t.loadMonthlyStats();

        if (document.getElementById('realtimeStr'))
            document.getElementById('realtimeStr').innerText = (new Date()).format('hh:mm:ss');

        setInterval(function () {
            if (document.getElementById('realtimeStr'))
                document.getElementById('realtimeStr').innerText = (new Date()).format('hh:mm:ss');
            if (document.getElementById('realtimeStr2'))
                document.getElementById('realtimeStr2').innerText = (new Date()).format('hh:mm:ss');
            if (document.getElementById('realtimeStr3'))
                document.getElementById('realtimeStr3').innerText = (new Date()).format('hh:mm:ss');
        }, 1000);

    },
    activated() {

        let t = this;

        copyFrom(t.f, t.U);
    },
    methods: {

        setView(v) {

            let t = this;

            if (v == t.view)
                return;

            if (v == 'workly-report') {

            }

            t.view = v;

            console.log(v);


        },

        logout() {
            localStorage.clear();
            location.reload('/#/signin');
        },
        refreshProfile() {

            let t = this;
            axios.post('employee/refreshProfile').then(res => {
                if (res.data.status == 1) {
                    if (!res.data.options.item) {
                        localStorage.removeItem('_m');
                        location.replace('/#/signin');
                    }
                    else {
                        localStorage['_m'] = JSON.stringify(res.data.options.item);
                        mirror(res.data.options.item, t.U);
                        copyFrom(t.f, t.U);
                    }
                }
            });

        },

        loadData() {
            let t = this;


        },

        onSelectDate() {
            this.f.birthday = this.selectedDate.format('yyyy-MM-dd');
            this.showCalendar = false;
            this.$forceUpdate();
        },


        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 600,
                minWdith: 600,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#fff',
            }).toBlob((blob) => {

                if (t.uploadImgType == 'avatar') {
                    t.avatarImageBlob = blob;
                    t.avatarIsUploaded = true;
                    t.f.avatar_url = getObjectURL(blob);
                }

                t.closeCropper();
            });
        },

        uploadAvatar() {

            let t = this;

            t.uploadImgType = 'avatar';

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 1 / 1,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        saveProfile() {
            let t = this;
            let f = t.f;

            if (t.busying)
                return;

            mirror(t.msg_o, t.msg);

            let go = true;

            if (!f.tel) {
                A.err('Telephone number is Required.');
                go = false;
            }

            if (!f.birthday) {
                A.err('Birthday is Required.');
                go = false;
            }

            if (!f.email) {
                A.err('Email is Required.');
                go = false;
            }

            if (!/^[a-zA-Z0-9\.]{1,}@[a-zA-Z0-9\.]{1,}[a-zA-Z0-9]$/.test(f.email)) {
                A.err('Please enter a valid email address.');
                go = false;
            }

            if (!f.emergency_tel) {
                A.err('Emergency telephone number is Required.');
                go = false;
            }

            if (!f.address) {
                A.err('Address is Required.');
                go = false;
            }

            if (!go)
                return;

            t.busying = true;

            let data = getFields(f, ['address', 'email', 'tel', 'emergency_tel', 'rc_img_url', 'avatar_url', 'gender', 'birthday', 'desc']);

            let fd = new FormData();

            for (const key in data) {
                fd.append(key, data[key]);
            }

            if (t.rcIsUploaded)
                fd.append('rc_image', t.rcImageBlob);

            if (t.avatarIsUploaded)
                fd.append('avatar_image', t.avatarImageBlob);

            t.busying = true;

            axios.post('employee/saveProfile', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {

                t.busying = false;

                t.avatarImageBlob = null;
                t.avatarIsUploaded = false;
                t.rcImageBlob = null;
                t.rcIsUploaded = false;

                if (res.data.status == 1) {
                    localStorage['_m'] = JSON.stringify(res.data.options.item);
                    t.$router.replace('/employee?v=profile');
                    location.reload();
                }
            });
        },

        loadMonthlyStats() {

            let t = this;

            let dayIndexs = ['日', '月', '火', '水', '木', '金', '土'];
            if (t.view == 'attendance') {

                t.list.dateGrid.clear();

                axios.post('cleanTask/readMonthly/' + t.month).then(res => {
                    if (res.data.status == 1) {

                        let dictDate = {};

                        let todayText = (new Date()).format('MM/dd');
                        let todayVal = new Date();

                        let startDate = new Date(res.data.options.start_date);
                        let endDate = new Date(res.data.options.end_date);

                        let i = 0;

                        while (true) {
                            let curDate = new Date(res.data.options.start_date);
                            curDate.setDate(curDate.getDate() + i);
                            if (curDate > endDate)
                                break;
                            let date = curDate.format('MM/dd');
                            let dayIndex = dayIndexs[curDate.getDay()];
                            let item = {
                                date: date,
                                day_index: dayIndex,
                                week_index: curDate.getDay(),
                                tasks: [],
                                is_today: date == todayText,
                                is_past: curDate < todayVal
                            };
                            dictDate[date] = item;
                            t.list.dateGrid.push(item);
                            i++;
                        }

                        let arr = res.data.options.items;

                        arr.forEach(task => {
                            let date = new Date(task.date).format('MM/dd');
                            let item = dictDate[date];
                            item.tasks.push(task);
                        });
                    }
                });

            }

        },

        minusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date((new Date()).getFullYear(), t.month - 1, 1), -1);
            t.month = r.getMonth() + 1;
            t.loadMonthlyStats();
        },

        plusMonth() {
            let t = this;
            let r = addMonthsToDate(new Date((new Date()).getFullYear(), t.month - 1, 1), 1);
            t.month = r.getMonth() + 1;
            t.loadMonthlyStats();
        }

    },
    data() {
        return {
            month: (new Date()).getMonth() + 1,
            selectedDate: null,
            minDate: new Date('1950/01/01'),
            maxDate: new Date('2023/01/01'),
            showCalendar: false,
            loading: false,
            view: 'clock-in',
            todayDateStr: '',
            realtimeStr: '',
            dict: {
            },
            list: {
            },
            f: {
                gender: 1,
                address: '',
                email: '',
                tel: '',
                birthday: '',
                emergency_tel: '',
                desc: '',
                rc_img_url: '',
                avatar_url: '',
                id: '',
            },
            cropper: null,
            uploadImgType: '',

        };
    },
    computed: {
    }
};
