

export default {


    created() {

        // document.body.classList.add('pc');

        let t = this;

        if(localStorage['ignorePasswordOnce'] == 1){
            localStorage.removeItem('ignorePasswordOnce');
            t.signIned = true;
        }

        t.minDate = new Date(2023, 10, 1);
        t.maxDate = new Date();

        t.maxDate.setDate(t.maxDate.getDate() + 365);

        t.v.date = (new Date()).format('yyyy-MM-dd');

        t.list.country = window['COUNTRY_LIST'];

        t.list.country.forEach(item => {
            item.icon = 'assets/img/flag/' + item.code + '.svg';
        });

        t.dict.country = listToDict(t.list.country, 'code');

        t.list.gender = [
            { id: 1, text: t.t('男') },
            { id: 2, text: t.t('女') },
            { id: 3, text: t.t('LGBTQ') },
        ];

        t.dict.gender = listToDict(t.list.gender);

        t.list.lang = [
            { id: 1, text: '日本人' },
            { id: 2, text: '「在留カード」を持ち外国人' },
            { id: 3, text: 'English' },
            { id: 4, text: '中文' },
            { id: 5, text: '한국어' },
        ];

        t.dict.lang = listToDict(t.list.lang);

        t.list.status = [
            { id: 1, text: t.t('Unconfirmed') },
            { id: 2, text: t.t('Confirmed') },
        ];

        t.dict.status = listToDict(t.list.status);

        document.addEventListener('keydown', function (event) {
            if (event.key === 'ArrowLeft') {
                t.minusDate();
            } else if (event.key === 'ArrowRight') {
                t.plusDate();
            }

            if (event.key === 'p' || event.key === 'P') {
                window.print();
            }
        });

        t.onceP = getURLParams();

        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods: {

        onPasswordChange(password) {
            let t = this;
            if (password == '8080') {
                t.signIned = true;
            }
        },

        loadData() {
            let t = this;

            axios.get('house').then(res => {

                if (res.data.status == 1) {

                    t.list.house = res.data.options.items;

                    t.list.house.forEach(house => {
                        house.rooms = [];
                    });

                    t.dict.house = listToDict(t.list.house);

                    axios.get('room').then(res => {

                        if (res.data.status == 1) {

                            let dictRoom = {};

                            t.list.room = res.data.options.items;

                            t.list.room.forEach(room => {

                                dictRoom[room.id] = room;

                                if (t.dict.house[room.house_id]) {
                                    t.dict.house[room.house_id].rooms.push(room);
                                }
                            });

                            let spliceHouse = {};

                            mirror(t.list.house[0], spliceHouse);

                            t.list.house[0].rooms = t.list.house[0].rooms.slice(0, 8)

                            spliceHouse.rooms.splice(0, 8);

                            t.list.house.splice(1, 0, spliceHouse);

                            let p = t.onceP;

                            if (p.date && p.rid) {
                                t.v.date = decodeURIComponent(p.date);
                                t.selectRoom(dictRoom[p.rid]);
                                t.onceP = {};
                            }
                            else {
                                t.selectRoom(dictRoom[44]);
                            }

                        }

                    });



                }
            });

        },

        onSelectDate(d) {

            let t = this;

            let date = d.format('yyyy-MM-dd');

            if (t.v.date == date)
                return;

            t.v.date = date;

            t.showDatePopover = false;

            t.loadForm();
        },

        selectRoom(room) {
            let t = this;

            if (room == t.v.room)
                return;

            t.v.room = room;
            t.v.house = t.dict.house[room.house_id];

            t.showRoomPopover = false;

            t.loadForm();
        },

        loadForm() {

            let t = this;

            axios.get('form/byDate/' + t.v.date + '?room_id=' + t.v.room.id).then(res => {
                t.form = res.data.options.item;
                if (t.form) {

                    t.passportUploader.items2 = [];

                    if (t.form.rc_urls) {
                        let urls = t.form.rc_urls.split(',');

                        t.passportUploader.items2 = [];
                        urls.forEach(url => {
                            t.passportUploader.items2.push(({ success: true, img_url: url, validation: false }));
                        });
                    }

                    t.passportUploader.items = [];

                    if (t.form.passport_urls) {
                        let urls = t.form.passport_urls.split(',');
                        let passportData = JSON.parse(t.form.passport_data);

                        t.passportUploader.items = [];

                        let i = 0;
                        urls.forEach(url => {
                            let data = passportData[i];
                            t.passportUploader.items.push(({ success: true, data: data, img_url: url, validation: true }));
                            i++;
                        });
                    }

                    if (t.v.room.static_password && t.v.room.static_password != 'AUTO') {
                        t.form.realPassword = t.v.room.static_password;
                    }
                    else {
                        if(t.form.door_password == 'ADMIN_PWD'){
                            t.form.realPassword = t.v.room.admin_password;
                        }
                        else{
                            t.form.realPassword = t.form.door_password.indexOf('#') > -1 ? t.form.door_password : (t.form.door_password + '#');
                        }
                    }
                }
            });

        },

        deleteForm() {

            let t = this;
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn-lg btn-orange',
                    cancelButton: 'btn-sm btn-dark mr10'
                },
                confirmButtonText: 'はい',
                cancelButtonText: 'いいえ',
                buttonsStyling: false,
                showCancelButton: true
            }).fire('データーを削除', "よろしいですか。", 'warning').then(res => {
                if (res.isConfirmed) {
                    axios.post('form/delete', { id: t.form.id }).then(res => {
                        t.loadData();
                    });
                }
            });

        },

        minusDate() {
            let t = this;

            let d = new Date(t.v.date.replace(/-/g, '/'));
            d.setDate(d.getDate() - 1);

            t.v.date = d.format('yyyy-MM-dd');

            t.loadForm();

            t.stopPropagation();
        },

        plusDate() {
            let t = this;

            let d = new Date(t.v.date.replace(/-/g, '/'));
            d.setDate(d.getDate() + 1);

            t.v.date = d.format('yyyy-MM-dd');

            t.loadForm();

            t.stopPropagation();
        }

    },
    data() {
        return {
            onceP:{},
            signIned: false,
            form: null,
            v: {
                date: '',
                room: null,
                house: null
            },
            list: {
                house: [],
                country: [],
                gender: [],
                lang: [],
                status: []
            },

            dict: {
                house: {},
                country: {},
                gender: {},
                lang: {},
                status: {}
            },

            showRoomPopover: false,
            showDatePopover: false,

            minDate: null,
            maxDate: null,

            passportUploader: {
                current: null,
                items: [
                ],
                items2: []
            },
        };
    },
};
