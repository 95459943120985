

export default {
    name: 'ModifyLockPwd',
    components: {
    },
    created() {

        let t = this;

        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {

            let t = this;

            axios.get('getAllLocks').then(res => {
                if (res.data.status == 1) {
                    t.list.lock = res.data.options.locks;
                    t.dict.lock = listToDict(t.list.lock);
                }
            });

        },

        onSubmit() {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            if (t.f.password[t.f.password.length - 1] != '#') {
                t.f.password += '#';
            }

            axios.post('room/setLockPwd', t.f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    t.lock.password = t.f.password;
                    t.f = {
                        lock_id: 0,
                        password: '',
                    };
                    A.toast('Saved.');
                }
                else {
                    A.err('Faild.');
                }
            });

        },

        selectLock(item) {
            this.lock = this.dict.lock[item];
        }

    },
    data() {
        return {
            dict: {
                lock: {}
            },
            list: {
                lock: [
                ],
            },
            f: {
                lock_id: 0,
                password: '',
            },
            search: {
                keywords: ''
            },
            lock: null
        };
    },
    computed: {
        filteredLocks() {
            let t = this;
            return t.list.lock.filter((item) => {
                if (item.name != undefined) {
                    if (item.name.indexOf(t.search.keywords) > -1) return true;
                }
                else {
                    if ((item.name).indexOf(t.search.keywords) > -1) return true;
                }
                return false;
            });
        },
    }
};
