

export default {

    created() {

        let t = this;
        document.body.classList.remove('pc');

        t.checkSignIn();
    },
    activated() {

        let t = this;

        t.checkSignIn();

    },
    methods: {

        checkSignIn() {

            if (localStorage['_m']) {

                let user = JSON.parse(localStorage['_m']);
                
                if (user.role == 1) {
                    // if (window.screen.availWidth < 1100 && /iphone|android|harmony/gi.test(navigator.userAgent))
                    //     location.replace('/#/manager');
                    // else
                    location.replace('/#/v2');
                }
                else if (user.role == 4) {
                    location.replace('/#/employee');
                }
                else if (user.role == 2 || user.role == 3) {
                    location.replace('/#/cleaner');
                }

            }


        },

        signIn() {
            let t = this;
            let f = t.f;

            if (t.busying)
                return;

            mirror(t.msg_o, t.msg);

            let go = true;

            if (!f.email) {
                t.msg.email = 'Required';
                go = false;
            }

            if (!f.password) {
                t.msg.password = 'Required';
                go = false;
            }

            if (!go)
                return;


            t.busying = true;

            axios.post('employee/signIn', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    let user = res.data.options.item;
                    localStorage['_m'] = JSON.stringify(user);

                    t.afterLogin();

                    if (user.role == 1) {
                        // if (window.screen.availWidth < 1100 && /iphone|android|harmony/gi.test(navigator.userAgent))
                        //     location.replace('/#/manager');
                        // else
                        location.replace('/#/v2');
                    }
                    else if (user.role == 4) {
                        location.replace('/#/employee');
                    }
                    else if (user.role == 2 || user.role == 3) {
                        location.replace('/#/cleaner');
                    }

                }
                else {
                    A.err('Email or password incorrect, please check.');
                }

            });

        }
    },
    data() {
        return {
            msg: {
                email: '',
                password: '',
            },
            msg_o: {
                email: '',
                password: '',
            },
            f: {
                email: '',
                password: '',
            },

            list: {
            },

        };
    },
};
